import { render, staticRenderFns } from "./filtrateList.vue?vue&type=template&id=0415145a&scoped=true&"
import script from "./filtrateList.vue?vue&type=script&lang=js&"
export * from "./filtrateList.vue?vue&type=script&lang=js&"
import style0 from "./filtrateList.vue?vue&type=style&index=0&id=0415145a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0415145a",
  null
  
)

export default component.exports