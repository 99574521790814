<template>
    <div class="pr footer">
        <div class="footer_con">
            <ul>
                <li>
                    <img src="../assets/images/icon_trust.png">
                    <p>值得信赖</p>
                </li>
                <li>
                    <img src="../assets/images/icon_Abargain.png">
                    <p>超值购物</p>
                </li>
                <li>
                    <img src="../assets/images/icon_manufacturer.png">
                    <p>厂家直购</p>
                </li>
                <li>
                    <img src="../assets/images/icon_trt.png">
                    <p>正品行货</p>
                </li>
                <li>
                    <img src="../assets/images/icon_ffordable.png">
                    <p>价格实惠</p>
                </li>
                <li>
                    <img src="../assets/images/icon_distribution.png">
                    <p>急速配送</p>
                </li>

            </ul>
        </div>

        <div class="footer_text">
            <p>山东企创电子技术有限公司© 版权所有</p>
            <p>
                <b @click="goCountUs">关于我们</b> |
                <b @click="goFeedBack">建议意见</b>  |
                <b @click="goAbout"> 联系我们</b>  |
                <b @click="goNote"> 免责声明</b>  |
                <b @click="goPrivacy"> 隐私政策</b>  |
                <a target="_blank" href="http://tstatics.76sd.com/static/dmyp_api/image/yingye_img.jpg"> 营业执照</a>
            </p>
          <a target="_blank" href="https://beian.miit.gov.cn/">
            <p>ICP备案：鲁ICP备19055611号-5</p>
          </a>
        </div>

    </div>
</template>
<script>
    export default {
        data(){
            return{

            }
        },
        methods:{
            goCountUs(){
                this.$router.push({name:'aboutUs',query:{tab:0}})
            },
            goFeedBack(){
                this.$router.push({name:'aboutUs',query:{tab:1}})
            },
            goAbout(){
                this.$router.push({name:'aboutUs',query:{tab:2}})
            },
            goNote(){
                this.$router.push({name:'aboutUs',query:{tab:3}})
            },
            goPrivacy(){
                this.$router.push({name:'aboutUs',query:{tab:4}})
            },
        
        }
    }
</script>
<style lang="less" scoped>
.footer{
    width: 100%;
    background: #414141;
    .footer_con{
        width: 1200px;
        height: 116px;
        margin: 0 auto;
        border-bottom: 1px solid #5E5E5E;
        ul{
            width: 1200px;
            height: 116px;

            li{
                float: left;
                width: 200px;
                color: #fff;
                line-height: 116px;
                img{
                    float: left;
                    margin-top: 40px;
                    margin-left: 30px;
                    margin-right: 20px;
                }
                p{
                    float: left;
                    font-size: 16px;
                }
            }
        }

    }
    .footer_text{
        width: 1200px;
        padding: 30px 0 40px 0;
        color: #fff;
        text-align: center;
        margin: 0 auto;
        color: #C9C9C9;
        font-size: 14px;
        b,a{
            cursor: pointer;
            color: #C9C9C9;
        }
        b:hover,a:hover{
            color: #E60026;
        }
    }
}
</style>
