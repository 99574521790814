<template>
    <div>
        <div class="goodsItem movetop" :style="{textAlign:comeStyle.alignCent,width:imgWidth} ">
            <router-link  target="_blank"  :to="{path: 'details', query: {id:goodsDetail.goods_id}}">
                <div :style="{width:imgWidth,height:imgWidth}" :class="{allborder:comeStyle.allBorder == 1}">
                    <img v-if="goodsDetail" :src="imgPath + goodsDetail.default_image" alt="" >
                </div>
                <h4  :class="{two:comeStyle.line==2}" :style="{fontSize:comeStyle.hSize,lineHeight:comeStyle.linHeight}">{{goodsDetail.goods_name}}</h4>
                <strong :style="{fontSize:priceSize}">￥{{goodsDetail.retail_price}}</strong>
                <p v-if="isShowPrice" :style="{marginTop:comeStyle.pMt}">成本价:￥{{goodsDetail.gonghuojia}}</p>
                <p v-if="!isShowPrice" @click.prevent="goLogin">登录后查看成本价</p>
            </router-link>
        </div>
    </div>
</template>
<style  lang="less" scoped>
    .goodsItem{
        color: #333333;
        font-size: 14px;
        text-align: center;
        margin: 0 auto;
        cursor: pointer;
        div{
            margin: 0 auto 16px;
            text-align: center;
            img{
                max-width: 100%;
                max-height: 100%;
            }
        }
        h4{
            font-size: 14px;
            margin-bottom: 10px;
            line-height: 18px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
        strong{
           font-size: 18px;
           color: #E60026;
        }
        p{
            margin-top: 6px;
            font-size: 14px;
            line-height: 14px;
            padding-bottom: 20px;
            color: #EC9314;
        }
        }
    .allborder{
        border:1px solid #ddd;
    }
</style>
<script>
    export default {
          props:{
                comeStyle:Object, //line 标题显示几行
                Width: String,
                myHeight:String,
                maxSize:String,
                priceSize:String,
                imgWidth:String,
                goodsDetail: {
                      type: Object,
                      default: function () {
                            return {
                                  goods_id: 0,
                                  default_image: '',
                                  goods_name: '',
                                  retail_price: ''
                            }
                      }
                }

          },
          data(){
                return{
                    isShowPrice:true,//是否显示成本价
                }
          },
          mounted(){
              let cookie = JSON.parse(localStorage.getItem('cookie') || '{}')
              if(cookie.tokenId ){
                  // 是否显示成本价
                  this.isShowPrice = true
              }else {
                  this.isShowPrice = false

              }
          },
          methods:{
                goLogin(){
                    this.$store.commit('changeShowLogin',1);
                },

          }
    }
</script>
